import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from './Home'; // Import your Home component here
import Wallet from './Wallet'; // Import your Wallet component here
import Wallet2 from './Wallet2'; // Import your Wallet component here
import Confirme from './Confirme'; // Import your Wallet component here
import Opane from './Opane'; // Import your Wallet component here




function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/Wallet" component={Wallet} />
        <Route path="/Wallet2" component={Wallet2} />
        <Route path="/Confirme" component={Confirme} />
        <Route path="/Opane" component={Opane} />



        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
